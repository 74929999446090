import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';

import { type commonAttributesType } from '../../ai-answer-dialog/types';

import {
	AutoHighlightsProduct,
	KeyPhraseCategory,
	type KeyPhraseNodeType,
} from './api/useAutohighlightSupplier';
import { getHighlightCategories, sanitizeSources, sha256Hash } from './common/utils';

// New instrumentation attributes must be added to this function to be included in all the events
export const filterCommonAttributes = (props: commonAttributesType) => {
	const {
		appliedFilters,
		answerFormat,
		answerLength,
		answerCardType,
		followUps,
		sources,
		extraAttributes,
		searchSessionId,
		queryHash,
		query,
		answerString,
		apiSource,
	} = props;
	return {
		searchSessionId,
		queryHash,
		wordCount: (query.match(/\w+/g) || []).length,
		queryLength: query.length,
		answerHash: sha256Hash(answerString),
		appliedFilters,
		answerFormat,
		answerLength,
		answerCardType,
		followUpsCount: followUps.length,
		followUpsLength: followUps.map((followUp) => followUp.length),
		sources: sanitizeSources(sources),
		apiSource,
		...extraAttributes,
	};
};

export const onCuratedDefinitionUpdateButtonClicked = ({
	commonAttributes,
	newDefinition,
	restrictedScope,
	sourceSelected,
	aiDefinitionEdited,
}: {
	commonAttributes: commonAttributesType;
	newDefinition: string;
	restrictedScope: string;
	sourceSelected: boolean;
	aiDefinitionEdited: boolean;
}): AnalyticsEventPayload => {
	const {
		query: keyPhrase,
		answerString: oldDefinition,
		extraAttributes,
		source,
	} = commonAttributes;
	return {
		action: 'clicked',
		actionSubject: 'aiCuratedDefinitionUpdateButton',
		source,
		eventType: 'ui',
		attributes: {
			// contentId and readingAidsSessionId passed in from Confluence readingAids
			...extraAttributes,
			restrictedScope,
			sourceSelected,
			newDefinitionLength: newDefinition.length,
			// whether the user is editing an AI answer or already edited definition
			aiDefinitionEdited,
		},
		nonPrivacySafeAttributes: {
			keyPhrase,
			oldDefinition,
			newDefinition,
		},
	};
};

export const onCuratedDefinitionSeeEditHistoryButtonClicked = (
	props: commonAttributesType,
): AnalyticsEventPayload => {
	const { query, answerString, extraAttributes, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiCuratedDefinitionSeeEditHistoryButton',
		source,
		eventType: 'ui',
		attributes: {
			// contentId and readingAidsSessionId passed in from Confluence readingAids
			...extraAttributes,
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

export const onLikeButtonClick = (props: commonAttributesType): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiAnswerLikeButton',
		source: source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

export const onDislikeButtonClick = (props: commonAttributesType): AnalyticsEventPayload => {
	const { query, answerString, source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiAnswerDislikeButton',
		source: source,
		eventType: 'ui',
		attributes: {
			...filterCommonAttributes(props),
		},
		nonPrivacySafeAttributes: {
			query,
			answerString,
		},
	};
};

export const onShownAutoDefinitionHighlights = ({
	product,
	contentId,
	keyPhrases,
}: {
	product: AutoHighlightsProduct;
	contentId: string;
	keyPhrases: KeyPhraseNodeType[];
}): AnalyticsEventPayload => ({
	action: 'shown',
	actionSubject: 'aiDefinitionsAutohighlights',
	source: 'viewPageScreen',
	eventType: 'track',
	attributes: {
		product,
		contentId,
		numHighlights: keyPhrases.length,
		categories: getHighlightCategories(keyPhrases),
		beAcronymsEnabled: true,
	},
});

export const onClickAutoDefinitionHighlights = ({
	contentId,
	sessionId,
	keyPhraseCategory,
}: {
	contentId: string;
	sessionId: string;
	keyPhraseCategory: KeyPhraseCategory | undefined;
}): AnalyticsEventPayload => ({
	action: 'clicked',
	actionSubject: 'aiDefinitionsAutohighlights',
	source: 'viewPageScreen',
	eventType: 'ui',
	attributes: {
		contentId,
		readingAidsSessionId: sessionId,
		keyPhraseCategory: keyPhraseCategory || KeyPhraseCategory.AUTO,
	},
});
