import crypto from 'crypto';

import { type NLPSearchResultSource } from '../../../ai-answer-dialog/types';
import { type KeyPhraseNodeType } from '../api/useAutohighlightSupplier';

export const sha256Hash = (str: string): string =>
	crypto.createHash('sha256').update(str).digest('hex');

export const sanitizeSources = (sources: NLPSearchResultSource[]): { id: string; type: string }[] =>
	sources.map((source) => ({
		id: source.ari ?? source.id,
		type: source.type,
	}));

export const getHighlightCategories = (nodes: KeyPhraseNodeType[]) => {
	const categories = new Map<string, number>();
	try {
		nodes.forEach((node) => {
			const curCategoryCount = categories.get(node.category);
			if (!!curCategoryCount) {
				categories.set(node.category, curCategoryCount + 1);
			} else {
				categories.set(node.category, 1);
			}
		});
	} catch {
		return undefined;
	}
	return Object.fromEntries(categories);
};
