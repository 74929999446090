import { useCallback, useMemo, useState } from 'react';

import { type AnalyticsEventPayload, useAnalyticsEvents } from '@atlaskit/analytics-next';
import { fg } from '@atlaskit/platform-feature-flags';
import { deleteSessionItem, getSessionItem, setSessionItem } from '@atlassian/search-dialog';

import { fetchAggResponse } from '../../../common';
import { DEFAULT_GAS_CHANNEL } from '../../../constants';
import { onShownAutoDefinitionHighlights } from '../analytics';
import { KEY_PHRASES_OPERATION_NAME, KeyPhrasesQuery } from '../gql/keyphrases-query';

type AutohighlightSupplierArgs = {
	product: AutoHighlightsProduct;
	cloudId: string;
	activationId: string;
	contentId: string;
	contentType: string;
	jiraReporterAccountId?: string;
	jiraAssigneeAccountId?: string;
	inputText?: string;
	subProduct?: string;
};

export type KeyPhraseNodeType = {
	keyPhrase: string;
	category: KeyPhraseCategory;
};

type AutohighlightSupplierState = {
	keyPhrases: KeyPhraseNodeType[];
	keyPhraseCategory: KeyPhraseCategory;
	autoHighlightRegex: RegExp;
	error: Error | undefined;
	loadAutohighlights: ({
		contentId,
		inputText,
	}: {
		contentId?: string;
		inputText?: string;
	}) => Promise<KeyPhraseNodeType[]>;
	resetAutohighlights: () => void;
	autohighlightsLoading: boolean;
};

enum KnowledgeDiscoveryKeyPhraseInputTextFormat {
	PLAIN = 'PLAIN',
	ADF = 'ADF',
}
type FetchKeyPhrasesArgs = {
	product: AutoHighlightsProduct;
	workspaceId: string;
	entityAri: string;
	jiraReporterAccountId?: string;
	jiraAssigneeAccountId?: string;
	inputText?: string;
};
type KeyPhrasesQueryVariables = {
	workspaceId: string;
	entityAri: string;
	jiraReporterAccountId?: string | null;
	jiraAssigneeAccountId?: string | null;
	inputText?: { text?: string; format: KnowledgeDiscoveryKeyPhraseInputTextFormat } | null;
};
export enum KeyPhraseCategory {
	PROJECT = 'PROJECT',
	TEAM = 'TEAM',
	ACRONYM = 'ACRONYM',
	AUTO = 'AUTO',
	OTHER = 'OTHER',
}
export enum AutoHighlightsProduct {
	JIRA = 'jira',
	CONFLUENCE = 'confluence',
}

export interface KeyPhrasesQueryType {
	knowledgeDiscovery: {
		keyPhrases: {
			nodes: KeyPhraseNodeType[];
		};
	};
}
export const AUTOHIGHLIGHTS_CACHE_KEY = 'atlassian.knowledge-discovery.autohighlights';

const generateKeyPhraseQueryArgs = ({
	product,
	workspaceId,
	entityAri,
	jiraReporterAccountId,
	jiraAssigneeAccountId,
	inputText,
}: FetchKeyPhrasesArgs) => {
	switch (product) {
		case AutoHighlightsProduct.CONFLUENCE:
			return {
				workspaceId,
				entityAri,
			};
		case AutoHighlightsProduct.JIRA:
			return {
				workspaceId,
				entityAri,
				jiraReporterAccountId,
				jiraAssigneeAccountId,
				inputText: {
					text: inputText,
					format: KnowledgeDiscoveryKeyPhraseInputTextFormat.PLAIN,
				},
			};
		default:
			return { workspaceId, entityAri };
	}
};

export const fetchKeyPhrases = async ({
	product,
	workspaceId,
	entityAri,
	jiraReporterAccountId,
	jiraAssigneeAccountId,
	inputText,
}: FetchKeyPhrasesArgs) => {
	const queryVariables = generateKeyPhraseQueryArgs({
		product,
		workspaceId,
		entityAri,
		jiraReporterAccountId,
		jiraAssigneeAccountId,
		inputText,
	});
	const keyPhrasesResult = await fetchAggResponse<KeyPhrasesQueryVariables, KeyPhrasesQueryType>({
		graphQuery: KeyPhrasesQuery,
		variables: queryVariables,
		operationName: KEY_PHRASES_OPERATION_NAME,
	});
	const keyPhrases = keyPhrasesResult?.data?.knowledgeDiscovery?.keyPhrases?.nodes
		? keyPhrasesResult.data.knowledgeDiscovery.keyPhrases.nodes
		: [];

	return {
		keyPhrases,
	};
};

const getHighlightRegex = (keyPhrases: KeyPhraseNodeType[]): RegExp => {
	return keyPhrases.length
		? new RegExp(keyPhrases.map((node) => `\\b${node.keyPhrase}\\b`).join('|'), 'gi')
		: new RegExp('(?!)');
};

const getKeyphraseCategory = (keyPhrases: KeyPhraseNodeType[]): KeyPhraseCategory => {
	return keyPhrases.length ? keyPhrases[0].category : KeyPhraseCategory.AUTO;
};

const initialState = {
	keyPhrases: [],
	keyPhraseCategory: KeyPhraseCategory.AUTO,
	autoHighlightRegex: new RegExp('(?!)'),
	loadAutohighlights: () => Promise.resolve([]),
	resetAutohighlights: () => {},
	autohighlightsLoading: false,
	error: undefined,
};

export const useAutohighlightSupplier = ({
	cloudId,
	activationId,
	contentId,
	contentType,
	jiraReporterAccountId,
	jiraAssigneeAccountId,
	product,
	subProduct,
}: AutohighlightSupplierArgs) => {
	const [state, setState] = useState<AutohighlightSupplierState>(initialState);
	const entityAri = `ari:cloud:${product}:${cloudId}:${contentType}/${contentId}`;
	const workspaceId = `ari:cloud:${subProduct ?? product}:${cloudId}:workspace/${activationId}`;

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireAnalyticsEvent = useCallback(
		(payload: AnalyticsEventPayload) => {
			createAnalyticsEvent(payload).fire(DEFAULT_GAS_CHANNEL);
		},
		[createAnalyticsEvent],
	);

	const loadAutohighlights = useCallback(
		async ({ contentId = '', inputText = '' }: { contentId?: string; inputText?: string }) => {
			if (!fg('kd_definitions_autohighlight_supplier')) {
				return [];
			}
			const keyPhraseCache = JSON.parse(getSessionItem(AUTOHIGHLIGHTS_CACHE_KEY) || '{}');

			if (
				!!contentId &&
				!!keyPhraseCache?.entityAri &&
				keyPhraseCache.entityAri.includes(contentId)
			) {
				if (fg('kd_definitions_click_and_show_event_product_parity')) {
					fireAnalyticsEvent(
						onShownAutoDefinitionHighlights({
							product,
							contentId,
							keyPhrases: keyPhraseCache?.keyPhrases || [],
						}),
					);
				}

				setState({
					...initialState,
					keyPhrases: keyPhraseCache?.keyPhrases || [],
					keyPhraseCategory: getKeyphraseCategory(keyPhraseCache?.keyPhrases || []),
					autoHighlightRegex: getHighlightRegex(keyPhraseCache?.keyPhrases || []),
				});

				return keyPhraseCache?.keyPhrases;
			} else {
				deleteSessionItem(AUTOHIGHLIGHTS_CACHE_KEY);
				try {
					setState({
						...initialState,
						autohighlightsLoading: true,
					});
					const { keyPhrases } = await fetchKeyPhrases({
						product,
						workspaceId,
						entityAri,
						...(!!jiraReporterAccountId && { jiraReporterAccountId }),
						...(!!jiraAssigneeAccountId && { jiraAssigneeAccountId }),
						...(!!inputText && { inputText }),
					});

					if (fg('kd_definitions_click_and_show_event_product_parity')) {
						fireAnalyticsEvent(
							onShownAutoDefinitionHighlights({
								product,
								contentId,
								keyPhrases,
							}),
						);
					}

					const autoHighlightRegex = getHighlightRegex(keyPhrases);
					setState({
						...initialState,
						keyPhraseCategory: getKeyphraseCategory(keyPhrases),
						autoHighlightRegex,
						keyPhrases,
					});
					setSessionItem(AUTOHIGHLIGHTS_CACHE_KEY, JSON.stringify({ keyPhrases, entityAri }));

					return keyPhrases;
				} catch (error: any) {
					setState({
						...initialState,
						error,
					});
					setSessionItem(AUTOHIGHLIGHTS_CACHE_KEY, JSON.stringify({ keyPhrases: [], entityAri }));
					return [];
				}
			}
		},
		[
			entityAri,
			jiraReporterAccountId,
			jiraAssigneeAccountId,
			product,
			workspaceId,
			fireAnalyticsEvent,
		],
	);

	const resetAutohighlights = useCallback(() => {
		setState(initialState);
	}, []);

	const autoHighlightRegex = useMemo(() => state.autoHighlightRegex, [state.autoHighlightRegex]);
	const autohighlightsLoading = useMemo(
		() => state.autohighlightsLoading,
		[state.autohighlightsLoading],
	);
	const keyPhrases = useMemo(() => state.keyPhrases, [state.keyPhrases]);

	return {
		keyPhrases,
		keyPhraseCategory: state.keyPhraseCategory,
		autoHighlightRegex,
		loadAutohighlights,
		resetAutohighlights,
		autohighlightsLoading,
	};
};
