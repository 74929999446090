import type { VFC } from 'react';

import {
	createSingleQueryParamHook,
	useRouteActions,
} from '@confluence/route-manager/entry-points/RouteState';

const useFocusedMentionIdQueryParam = createSingleQueryParamHook('focusedMentionId');

export const MentionScrollComponent: VFC = () => {
	const focusedMentionId = useFocusedMentionIdQueryParam();
	const { setQueryParams } = useRouteActions();

	if (focusedMentionId) {
		const mentionElement = document.querySelector(`[data-local-id="${focusedMentionId}"]`);
		if (mentionElement) {
			mentionElement.scrollIntoView({
				behavior: 'instant',
				inline: 'center',
				block: 'center',
			});
			// Remove the query param immediately after mention scroll is complete
			setQueryParams({
				focusedMentionId: undefined,
			});
		}
	}

	return null;
};
